import apis from "@/api/api";
import { ViewTable, Statistics, Layout } from "@/components";
import { COUPON_TYPE, SALE_STATUS } from "@/enum-data/enum";
import { routerMap } from "@/router/agent_router";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from 'antd';
import CouponGiveStatus from "./components/CouponGiveStatus";
const style = require('./coupon-style.less');

export default class CouponDetail extends React.PureComponent<RouterPropsType, {
  limitGiveCount: number;
  givenCount: number;
  usedCount: number;
  couponName: string;
  couponType: string;
  couponTypeDesc: string;
  limitGetCount: number;
  validityStartDate: string;
  validityEndDate: string;
  giveStatus: string;
  giveStatusDesc: string;
  fullAmount: string;
  minusAmount: string;
  getTimes: number;
  scopeDesc: string;
  useDesc: string;
}> {

  constructor(props) {
    super(props);
    this.state = {
      limitGiveCount: 0,
      givenCount: 0,
      usedCount: 0,
      couponName: '',
      couponType: '',
      couponTypeDesc: '',
      limitGetCount: 0,
      validityStartDate: '',
      validityEndDate: '',
      giveStatus: '',
      giveStatusDesc: '',
      fullAmount: '',
      minusAmount: '',
      getTimes: 0,
      scopeDesc: '',
      useDesc: ''
    }
  }

  componentDidMount() {
    apis["coupon.findDetail"].post({
      couponId: this.props.match?.params.id,
    })
    .then((data: any) => {
      const state = {...data};
      this.setState(state);
    })
  }

  render() {
    const {limitGiveCount, couponTypeDesc, givenCount, giveStatus, usedCount, couponName, limitGetCount, validityStartDate, validityEndDate, getTimes, scopeDesc, useDesc} = this.state;
    return <div className="coupon-sale-detail">
      <Layout.Title icon="icon-youhuiquan">{couponTypeDesc}</Layout.Title>
      <Form layout="horizontal" labelCol={{span: 5}} style={{width: 1200}}>
        <Row>
          <Col span={8}><Form.Item label="发放状态" style={{marginBottom: 0}}><CouponGiveStatus status={giveStatus as any}/></Form.Item></Col>
          <Col span={8}><Form.Item label="优惠券名称" style={{marginBottom: 0}}>{couponName}</Form.Item></Col>
          <Col span={8}><Form.Item label="领取方式" style={{marginBottom: 0}}>{limitGetCount === 0 ? '无限制' : '每人限领 ' + limitGetCount + ' 张'}</Form.Item></Col>
        </Row>
        <Row>
          <Col span={8}><Form.Item label="适用范围">{scopeDesc.split('：')[1]}</Form.Item></Col>
          <Col span={8}><Form.Item label="使用方式">{useDesc}</Form.Item></Col>
          <Col span={8}><Form.Item label="有效期">{validityStartDate.replaceAll('-', '.')} - {validityEndDate.replaceAll('-', '.')}</Form.Item></Col>
        </Row>
      </Form>
      <Statistics options={[
        {
          label: '计划发放（张）',
          value: limitGiveCount || '无限制'
        },
        {
          label: '已发放（张）',
          value: givenCount
        },
        {
          label: '已使用（张）',
          value: usedCount
        },
        {
          label: '已领取客户（位）',
          value: getTimes
        },
      ]}/>
      <div style={{flex: 1, overflow: 'hidden'}}>
        <ViewTable
          fetchOption={{
            api: apis["coupon.get.findAndCount"],
            data: {
              couponId: this.props.match?.params.id,
            },
          }}
          columns={[
            {
              title: '客户',
              width: 200,
              dataIndex: 'customerName',
            },
            {
              title: '领取时间',
              width: 180,
              align: 'center',
              dataIndex: 'createdAt',
            },
            {
              title: '使用记录',
              titleAlign: 'left',
              width: 'auto',
              dataIndex: 'saleId',
              render: v => {
                if (!v) return '未使用';
                return <Link to={routerMap.sale_order_detail.getPath(v, SALE_STATUS.WAIT_AUDIT.value)}>
                  订单编号：{v}
                </Link>
              },
            },
          ]}
        />
      </div>
    </div>
  }
}